//import request from '@/utils/request'
import $http, { $httpOrigin } from '@/utils/request'
import request from '@/utils/request'
import store from '@/store'

const url = process.env['VUE_APP_BASE_API']

export const uploadFile=(formData) =>{
  return $http.post(`/payrollDetail/upload`,formData,{ upload: true, timeout: 24 * 60 * 60 * 1000 });
}

export const uploadAllowanceFile=(formData) =>{
  return $http.post(`/payroll-allowance-detail/upload`,formData,{ upload: true, timeout: 24 * 60 * 60 * 1000 });
}

export const uploadAnnualFile=(formData) =>{
  return $http.post(`/payroll-annual-detail/upload`,formData,{ upload: true, timeout: 24 * 60 * 60 * 1000 });
}

export const uploadFTEFile=(formData) =>{
  return $http.post(`/payroll-fte-payment-detail/upload`,formData,{ upload: true, timeout: 24 * 60 * 60 * 1000 });
}

export const uploadFreeContFile=(formData) =>{
  return $http.post(`/payroll-free-cont-detail/upload`,formData,{ upload: true, timeout: 24 * 60 * 60 * 1000 });
}

export const uploadInternFile=(formData) =>{
  return $http.post(`/payroll-intern-pay-detail/upload`,formData,{ upload: true, timeout: 24 * 60 * 60 * 1000 });
}

export const actFileExport=(data)=>{
  window.location = `${url}/api/iris/payrollAct/export?periodId=${data.periodId}&hfmCode=${data.hfmCode}&localExpat=${data.localExpat}&location=${data.location}&csId=${data.csId}&chineseName=${data.chineseName}&token=${store.getters.token}`
}

export const payrollAllowanceExport=(data)=>{
  window.location = `${url}/api/iris/payroll-allowance-detail/export?periodUploadRecordId=${data.periodUploadRecordId}&viewQ=${data.viewQ}&hfmCode=${data.hfmCode}&agency=${data.agency}&departmentName=${data.departmentName}&employeeNumber=${data.employeeNumber}&empName=${data.empName}&token=${store.getters.token}`
}

export const payrollAnnualExport=(data)=>{
  window.location = `${url}/api/iris/payroll-annual-detail/export?csId=${data.csId}&employeeName=${data.employeeName}&company=${data.company}&location=${data.location}&org=${data.org}&jobTitle=${data.jobTitle}&slaryGroup=${data.slaryGroup}&periodUploadRecordId=${data.periodUploadRecordId}&token=${store.getters.token}`
}

export const payrollExport=(data)=>{
  window.location = `${url}/api/iris/payrollDetail/export?periodUploadRecordId=${data.periodUploadRecordId}&hfmCode=${data.hfmCode}&brand=${data.brand}&Location=${data.location}&csID=${data.csID}&chineseName=${data.chineseName}&token=${store.getters.token}&period=${data.period}`
}

export const freeContExport=(data)=>{
  window.location = `${url}/api/iris/payroll-free-cont-detail/export?periodUploadRecord=${data.periodUploadRecord}&costCenter=${data.costCenter}&name=${data.name}&token=${store.getters.token}`
}

export const fteExport=(data)=>{
  window.location = `${url}/api/iris/payroll-fte-payment-detail/export?periodUploadRecord=${data.periodUploadRecord}&hfmCode=${data.hfmCode}&bu=${data.bu}&socialInsuloaction=${data.socialInsuloaction}&csId=${data.csId}&cnName=${data.cnName}&token=${store.getters.token}&period=${data.period}`
}

export const internExport=(data)=>{
  window.location = `${url}/api/iris/payroll-intern-pay-detail/export?periodUploadRecord=${data.periodUploadRecord}&hfmCode=${data.hfmCode}&brand=${data.brand}&department=${data.department}&location=${data.location}&name=${data.name}&token=${store.getters.token}`
}

export const templateExport=(data)=>{
  window.location = `${url}/api/iris/payrollAct/exportTemplate?fileName=${data}&token=${store.getters.token}`
}
