import request from '@/utils/request'

export const addPeriod = (data) => {
  return request({
    url: '/payrollPeriod/add',
    method: 'POST',
    params: {
      ...data
    }
  })
}

export const submitPeriod = (data) => {
  return request({
    url: '/payrollPeriod/submit',
    method: 'POST',
    params: {
      ...data
    }
  })
}

export const getPeriodList = (data) => {
  return request({
    url: '/payrollPeriod/list',
    params: {
      ...data
    }
  })
}

export const getAllPeriodList = () => {
  return request({
    url: '/payrollPeriod/allList'
  })
}

export const getPeriodUpLoadList = (data) => {
  return request({
    url: '/payrollUploadRecord/list',
    params: {
      ...data
    }
  })
}

//ActualData
export const compute = (data) => {
  return request({
    url: '/payrollAct/compute',
    method: 'POST',
    params: {
      ...data
    }
  })
}

export const payrollActList = (data) => {
  return request({
    url: '/payrollAct/list',
    params: {
      ...data
    }
  })
}

export const getEntityHfmCode = () => {
  return request({
    url: '/hrRafForm/getEntityHfmCode',
  })
}

export const getBusinessUnitNameByHfmCode = (data) => {
  return request({
    url: '/hrRafForm/getBusinessUnitNameByHfmCode',
    params: {
      ...data
    }
  })
}


export const periodSubmit = (data) => {
  return request({
    url: '/payrollPeriod/submit',
    method:'POST',
    params: {
      ...data
    }
  })
}


//People+
export const getPayrollDetailList = (data) => {
  return request({
    url: '/payrollDetail/list',
    params: {
      ...data
    }
  })
}

export const getHfmCodes = (data) => {
  return request({
    url: '/payrollDetail/listHfmCodes'
  })
}

export const getBrands = (data) => {
  return request({
    url: '/payrollDetail/listBrands'
  })
}

export const getLocations = (data) => {
  return request({
    url: '/payrollDetail/listLocations'
  })
}

//Allowance
export const getPayrollAllowAnceDetailList = (data) => {
  return request({
    url: '/payroll-allowance-detail/list',
    params: {
      ...data
    }
  })
}

export const getPayrollViewQList = (data) => {
  return request({
    url: '/payroll-allowance-detail/viweQList'
  })
}

export const getPayrollHfmCodeList = (data) => {
  return request({
    url: '/payroll-allowance-detail/hfmCodeList'
  })
}

export const getPayrollAgencyList = (data) => {
  return request({
    url: '/payroll-allowance-detail/agencyList'
  })
}

export const getPayrollDepartmentList = (data) => {
  return request({
    url: '/payroll-allowance-detail/departmentList'
  })
}

//Annual 

export const getPayrollAnnualDetailList = (data) => {
  return request({
    url: '/payroll-annual-detail/list',
    params: {
      ...data
    }
  })
}


export const getPayrollLocationList = (data) => {
  return request({
    url: '/payroll-annual-detail/listLocation'
  })
}

export const getPayrollOrgList = (data) => {
  return request({
    url: '/payroll-annual-detail/listOrg'
  })
}

export const getPayrollJobTitleList = (data) => {
  return request({
    url: '/payroll-annual-detail/listJobTitle'
  })
}

export const getPayrollAnnualSalaryGroupList = (data) => {
  return request({
    url: '/payroll-annual-detail/listSalaryGroup'
  })
}

//fte
 
export const getPayrollFTEDetailList = (data) => {
  return request({
    url: '/payroll-fte-payment-detail/list',
    params: {
      ...data
    }
  })
}


export const getPayrollFTEHfmCodeList = (data) => {
  return request({
    url: '/payroll-fte-payment-detail/listHfmCode'
  })
}

export const getPayrollBuList = (data) => {
  return request({
    url: '/payroll-fte-payment-detail/listBu'
  })
}

export const getPayrollSocialInsuloactionList = (data) => {
  return request({
    url: '/payroll-fte-payment-detail/listSocialInsuloaction'
  })
}

//cont_free

export const getPayrollFreeContDetailList = (data) => {
  return request({
    url: '/payroll-free-cont-detail/list',
    params: {
      ...data
    }
  })
}


export const getPayrollCostCenterList = (data) => {
  return request({
    url: '/payroll-free-cont-detail/listCostCenter'
  })
}

//intern

export const getPayrollInternDetailList = (data) => {
  return request({
    url: '/payroll-intern-pay-detail/list',
    params: {
      ...data
    }
  })
}


export const getPayrollInternHfmCodeList = (data) => {
  return request({
    url: '/payroll-intern-pay-detail/listHfmCode'
  })
}

export const getPayrollBrandList = (data) => {
  return request({
    url: '/payroll-intern-pay-detail/listBrand'
  })
}

export const getPayrollInternDepartmentList = (data) => {
  return request({
    url: '/payroll-intern-pay-detail/listDepartment'
  })
}

export const getPayrollInternLocationList = (data) => {
  return request({
    url: '/payroll-intern-pay-detail/listLocation'
  })
}





