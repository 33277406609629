const ActDataTableColumn = [
  {
    key: 'periodName',
    name: 'Period',
    dataType: 'date', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: true, // 固定列的位置(left, right)
    width: 100, // 默认宽度，像素
    align:'left'
  },
  {
    key: 'creatorUser',
    name: 'Creator',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: 100,
    align:'left'
  },
  {
    key: 'submitStatus',
    name: 'Submit Status',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: 100,
    align:'left'
  },
  {
    key: 'subimtDate',
    name: 'Submit Date',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  },
  {
    key: 'computeStatus',
    name: 'compute_status',
    dataType: 'date',
    visible: false,
    sortable: false,
    fixed: true,
    width: false
  }
]

const ActDataDetailTableColumn = [
  {
    key: 'period',
    name: 'PeriodName',
    dataType: 'date', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: false, // 固定列的位置(left, right)
    width: 100 // 默认宽度，像素
  },
  {
    key: 'csid',
    name: 'CS ID',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'cnName',
    name: 'Chinese Name',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 150
  },
  {
    key: 'enName',
    name: 'English Name',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'location',
    name: 'Location',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'hfmCode',
    name: 'HFM Code',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'legalEntity',
    name: 'Legal Entity',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'startDate',
    name: 'Start Date',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: false,
    width: 140
  },
  {
    key: 'leavingDate',
    name: 'Leaving Date',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'localExpat',
    name: 'Local Expat',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'salaryOfMonth',
    name: 'Salary Of Month',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100,
    align:'right'
  },
  {
    key: 'allowancePerMonth',
    name: 'Allowance Per Month',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100,
    align:'right'
  },
  {
    key: 'basePayrollOp111',
    name: 'Base Payroll-OP111',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100,
    align:'right'
  },
  {
    key: 'thirteenPayrollOp111',
    name: '13th Payroll-OP111',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100,
    align:'right'
  },
  {
    key: 'holidayPayOp112',
    name: 'Holiday Pay Op112',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100,
    align:'right'
  },
  {
    key: 'salesCommOp113',
    name: 'Sales Comm Op113',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100,
    align:'right'
  },
  {
    key: 'socialChargesBase',
    name: 'Social Charges Base',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100,
    align:'right'
  },
  {
    key: 'housingFundBase',
    name: 'Housing Fund Base',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100,
    align:'right'
  },
  {
    key: 'socialChargesOp121',
    name: 'Social Charges Op121',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100,
    align:'right'
  },
  {
    key: 'medicalInsuranceOp126',
    name: 'Medical Insurance Op126',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100,
    align:'right'
  },
  {
    key: 'commInsuranceIitOp126',
    name: 'Comm Insurance IIT Op126',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100,
    align:'right'
  },
  {
    key: 'homeLeaveOp132',
    name: 'Home Leave Op132.01',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100,
    align:'right'
  },
  {
    key: 'housingSchoolFeeOthersOp132',
    name: 'Housing/School/Fee/others Op132.02',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100,
    align:'right'
  },
  {
    key: 'relocationOp132',
    name: 'Relocation Op132.05',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100,
    align:'right'
  },
  {
    key: 'fellingfeeOthersOp132',
    name: 'Felling Fee&Others Op132.03/04/06',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100,
    align:'right'
  },
  {
    key: 'severanceOp141',
    name: 'Severance Op141',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100,
    align:'right'
  },
  {
    key: 'bonusOp162Op164',
    name: 'Bonus Op162/Op164',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100,
    align:'right'
  }
]

const payrollRepoTableColumn = [
  {
    key: 'period',
    name: 'Period',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: true, // 固定列的位置(left, right)
    width: 100 // 默认宽度，像素
  },
  {
    key: 'creator',
    name: 'Creator',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: 100
  },
  {
    key: 'uploadStatus',
    name: 'Upload Status',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: 100
  },
  {
    key: 'uploadDate',
    name: 'Upload Date',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  },
  {
    key: 'submitStatus',
    name: 'Submit Status',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  },
  {
    key: 'subimtDate',
    name: 'Submit Date',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  },
  {
    key: 'remark',
    name: 'Remark',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  }
]

const payrollDetailTableColumn = [
  {
    key: 'groupName',
    name: 'Payroll group name',
    dataType: 'date', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: false, // 固定列的位置(left, right)
    width: 200 // 默认宽度，像素
  },
  {
    key: 'count',
    name: 'Count',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'periodName',
    name: 'Period name',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 110
  },
  {
    key: 'staffNum',
    name: '员工编号',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'csId',
    name: 'Career Settings ID',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 150
  },
  {
    key: 'cnName',
    name: '员工姓名',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'enName',
    name: '员工英文名称',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 150
  },
  {
    key: 'location',
    name: '地区名称',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'hfmCode',
    name: 'HFM Code',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'agency',
    name: 'Agency',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 150
  },
  {
    key: 'division',
    name: 'Division',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 250
  },
  {
    key: 'legalEntity',
    name: 'Legal Entity',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'brand',
    name: 'Brand',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'department',
    name: 'Department',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'team',
    name: 'Team',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'jobTitle',
    name: '岗位名称',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'startDate',
    name: '雇佣日期',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'leavingDate',
    name: '离职日期',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'employeeType',
    name: '员工类型',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'baseSalary',
    name: '基本工资',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'baseSalaryDiscount',
    name: '入离职基本工资折算扣减',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'adjustSalary',
    name: '调薪折算补或扣款',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'actualPayment',
    name: '实际支付工资',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'leaveCut',
    name: '事假扣款',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'sickCut',
    name: '病假扣款',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'beforeMaternityCut',
    name: '产前假和产假工资补扣',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'maternityCut',
    name: '产假垫付和补扣款',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'differenceCompensate',
    name: '补差额',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'annualLeaveCompensate',
    name: '年假补偿',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compensatoryLeave',
    name: '调休假补偿',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'workOvertimePayment',
    name: '加班费',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'mealAndTransAllowance',
    name: '安置补贴/异地补贴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'highTemSubsidy',
    name: '健身津贴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'birGift',
    name: '生日礼金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'midAutumnGift',
    name: '中秋礼金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'springFestivalGift',
    name: '春节礼金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'weddingGift',
    name: '结婚礼金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'bereavementExpenses',
    name: '丧亲费',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'newbornGift',
    name: '新生儿礼金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'housingSubsidy',
    name: '住房补贴(应税)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'livingAllowance',
    name: '生活补贴(应税)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'recommendationAward',
    name: '推荐奖',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'prize',
    name: '献血津贴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'communicationSubsidy',
    name: '话费津贴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'allowanceCategory',
    name: '津贴类（含税）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'annualRedEnvelope',
    name: '周年红包',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'hypotheticalTax',
    name: '假设税',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'foreignSocialSecurity',
    name: '国外社保类',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'paymentInLieuOfNotice',
    name: '代通知金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'winterSolsticeGift',
    name: '冬至礼金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'specialSubsidies',
    name: '其他补贴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'christmasGift',
    name: '圣诞节礼金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'otherSalaryAdjustments',
    name: '其它工资薪金调整',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'bonus',
    name: '奖金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'commission',
    name: '佣金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'thirteenSalary',
    name: '13薪',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'grossSalary',
    name: 'GrossSalary(without IIT only)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'comInsCompTake',
    name: '补税工资公司承担（商保含税）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'communicationCompAke',
    name: '补税工资公司承担（通讯费含税）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'cashGiftComTake',
    name: '补税工资公司承担（礼金含税）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'allowanceCompTake',
    name: '补税工资公司承担（津贴含税）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'travelComTake',
    name: '补税工资公司承担（旅游含税）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'otherCompTake',
    name: '补税工资公司承担（其它含税）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'commInsTaxSupplementSalary',
    name: '补税工资（商保）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'communicationTaxSupplementSalary',
    name: '补税工资（通讯费）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'cashGiftTaxSupplementSalary',
    name: '补税工资（礼金）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'otherTaxSupplementSalary',
    name: '其它补税项目',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'currentPreTaxIncome',
    name: '本期税前收入',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'employeeTotalSocialSecurity',
    name: '社保合计-员工',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'employeeTotalAccumulationFund',
    name: '公积金合计-员工',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'currentTaxableSalary',
    name: '当期应税工资',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'accumulatedIncome',
    name: '累计收入额',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'accumulatedSpecialDeduction',
    name: '累计专项扣除',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'accSpecialAdditionalDeduction',
    name: '累计专项附加扣除',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'preAccTaxableSalary',
    name: '累计应税工资（税前工资）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'accDeductionExpenses',
    name: '累计减除费用',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'accTaxableIncome',
    name: '累计应纳税所得额',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'accWithholdingTax',
    name: '累计应扣缴税额',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'accTaxWithheld',
    name: '累计已扣缴税额',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'currPersonalIncomeTax',
    name: '本期工资薪金个税',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'oneOffYearbonus',
    name: '年终奖(全年一次性)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'oneOffThireteenSalary',
    name: '13薪(全年一次性)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'oneOffBirthdayGift',
    name: '生日礼金(全年一次性)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'oneOffOtherBonus',
    name: '其它奖金项目(全年一次性)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'oneOffCommission',
    name: '佣金(全年一次性)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'oneOffBonus',
    name: '奖金(全年一次性)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'noTaxYearbouns',
    name: '年终奖（不含税）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'includeTaxYearbouns',
    name: '年终奖（含税）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'yearbonusAssumptionTax',
    name: '年终奖假设税',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'yearbounsPersonalTaxCompBear',
    name: '公司承担年终奖个税',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'yearbounsPersonalTax',
    name: '年终奖个税',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'severanceCompensation',
    name: '离职补偿金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'oneOfflieuNotice',
    name: '代通知金(一次性)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'severanceCompensationTax',
    name: '离职补偿金税',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'currIndividualTotalTax',
    name: '个人当期个税总额',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'individualTotalTaxCompTake',
    name: '公司承担个税总额',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'noTaxAllowanceCategory',
    name: '津贴类（不含税）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'noTaxCommInsurancecb',
    name: '补税工资公司承担（商保不含税）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'noTaxCommcb',
    name: '补税工资公司承担（通讯费不含税）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'noTaxCashGiftcb',
    name: '补税工资公司承担（礼金不含税）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'noTaxAllowancecb',
    name: '补税工资公司承担（津贴不含税）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'noTaxTravelcb',
    name: '补税工资公司承担（旅游不含税）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'noTaxOthercb',
    name: '补税工资公司承担（其它不含税）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'indemnity',
    name: '赔款',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'arrears',
    name: '公司垫款',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'oneChildPaymant',
    name: '独生子女费',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'governmentAllowance',
    name: '由政府发放津贴类',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'afterTaxAdjustment',
    name: '税后调整',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'netSalary',
    name: '实发工资',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'netSalaryrmb',
    name: '实发工资_人民币',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'foreignCurrencyPayment1',
    name: '实发外币1',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'foreignCurrencyPayment2',
    name: '实发外币2',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'remark',
    name: '备注',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'socialSecurityComp',
    name: '社保合计-公司',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'accFundComp',
    name: '公积金合计-公司',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'serviceFee',
    name: '服务费',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
]

const payrollNewDetailTableColumn = [
  {
    key: 'groupName',
    name: 'Payroll group name',
    dataType: 'date', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: false, // 固定列的位置(left, right)
    width: 200 // 默认宽度，像素
  },
  {
    key: 'count',
    name: 'Count',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'periodName',
    name: 'Period name',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 110
  },
  {
    key: 'staffNum',
    name: '员工编号',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'csId',
    name: 'Career Settings ID',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 150
  },
  {
    key: 'cnName',
    name: '员工姓名',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'enName',
    name: '员工英文名称',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 150
  },
  {
    key: 'hfmCode',
    name: 'HFM Code',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'location',
    name: '地区名称',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'agency',
    name: 'Agency',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 150
  },
  {
    key: 'division',
    name: 'Division',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 250
  },
  {
    key: 'legalEntity',
    name: 'Legal Entity',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'brand',
    name: 'Brand',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'department',
    name: 'Department',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'team',
    name: 'Team',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'jobTitle',
    name: '岗位名称',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'startDate',
    name: '雇佣日期',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'leavingDate',
    name: '离职日期',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'employeeType',
    name: '员工类型',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'baseSalary',
    name: '基本工资',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'baseSalaryDiscount',
    name: '入离职基本工资折算扣减',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'adjustSalary',
    name: '调薪折算补或扣款',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'actualPayment',
    name: '实际支付工资',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'leaveCut',
    name: '事假扣款',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'sickCut',
    name: '病假扣款',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'beforeMaternityCut',
    name: '产前假和产假工资补扣',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'maternityCut',
    name: '假期垫付和补扣款',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'differenceCompensate',
    name: '补差额',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'annualLeaveCompensate',
    name: '年假补偿',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'workOvertimePayment',
    name: '加班费',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'mealAndTransAllowance',
    name: '安置补贴/异地补贴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'highTemSubsidy',
    name: '健身津贴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'birGift',
    name: '生日礼金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'midAutumnGift',
    name: '节日礼金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'bereavementExpenses',
    name: '丧亲费',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'housingSubsidy',
    name: '住房补贴(应税)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'livingAllowance',
    name: '生活补贴(应税)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'recommendationAward',
    name: '推荐奖',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'communicationSubsidy',
    name: '话费津贴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'allowanceCategory',
    name: '津贴类（含税）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'specialSubsidies',
    name: '其他补贴/津贴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'otherSalaryAdjustments',
    name: '其它工资薪金调整',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'bonus',
    name: '奖金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'commission',
    name: '佣金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'thirteenSalary',
    name: '13薪',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'grossSalary',
    name: 'GrossSalary(without IIT only)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'comInsCompTake',
    name: '补税工资公司承担（商保含税）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'cashGiftComTake',
    name: '补税工资公司承担（礼金含税）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'commInsTaxSupplementSalary',
    name: '补税工资（商保）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'cashGiftTaxSupplementSalary',
    name: '补税工资（礼金）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'otherTaxSupplementSalary',
    name: '其它补税项目',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'currentPreTaxIncome',
    name: '本期税前收入',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'employeeTotalSocialSecurity',
    name: '社保合计-员工',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'employeeTotalAccumulationFund',
    name: '公积金合计-员工',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'currentTaxableSalary',
    name: '当期应税工资',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'accumulatedIncome',
    name: '累计收入额',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'accumulatedSpecialDeduction',
    name: '累计专项扣除',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'accSpecialAdditionalDeduction',
    name: '累计专项附加扣除',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'preAccTaxableSalary',
    name: '累计应税工资（税前工资）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'accDeductionExpenses',
    name: '累计减除费用',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'accTaxableIncome',
    name: '累计应纳税所得额',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'accWithholdingTax',
    name: '累计应扣缴税额',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'accTaxWithheld',
    name: '累计已扣缴税额',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'currPersonalIncomeTax',
    name: '本期工资薪金个税',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'oneOffYearbonus',
    name: '年终奖(全年一次性)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'oneOffThireteenSalary',
    name: '13薪(全年一次性)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'oneOffOtherBonus',
    name: '其它奖金项目(全年一次性)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'oneOffCommission',
    name: '佣金(全年一次性)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'oneOffBonus',
    name: '奖金(全年一次性)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'yearbounsPersonalTax',
    name: '年终奖个税',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'severanceCompensation',
    name: '离职补偿金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'severanceCompensationTax',
    name: '离职补偿金税',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'currIndividualTotalTax',
    name: '个人当期个税总额',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'individualTotalTaxCompTake',
    name: '公司承担个税总额',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'noTaxAllowanceCategory',
    name: '津贴类（不含税）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'noTaxCommInsurancecb',
    name: '补税工资公司承担（商保不含税）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'noTaxCashGiftcb',
    name: '补税工资公司承担（礼金不含税）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'indemnity',
    name: '赔款',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'arrears',
    name: '公司垫款',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'oneChildPaymant',
    name: '独生子女费',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'governmentAllowance',
    name: '由政府发放津贴类',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'afterTaxAdjustment',
    name: '税后调整',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'netSalary',
    name: '实发工资',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'netSalaryrmb',
    name: '实发工资_人民币',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'foreignCurrencyPayment1',
    name: '实发外币1',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'foreignCurrencyPayment2',
    name: '实发外币2',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'remark',
    name: '备注',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'socialSecurityComp',
    name: '社保合计-公司',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'accFundComp',
    name: '公积金合计-公司',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'serviceFee',
    name: '服务费',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
]

const allowanceRepoTableColumn = [
  {
    key: 'period',
    name: 'Period',
    dataType: 'date', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: true, // 固定列的位置(left, right)
    width: 100 // 默认宽度，像素
  },
  {
    key: 'creator',
    name: 'Creator',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: 100
  },
  {
    key: 'uploadStatus',
    name: 'Upload Status',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: 100
  },
  {
    key: 'uploadDate',
    name: 'Upload Date',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  },
  {
    key: 'submitStatus',
    name: 'Submit Status',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  },
  {
    key: 'subimtDate',
    name: 'Submit Date',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  },
  {
    key: 'remark',
    name: 'Remark',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  }
]

const allowanceDetailRepoTableColumn = [
  {
    key: 'viewQ',
    name: 'View Q',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: false, // 固定列的位置(left, right)
    width: 200 // 默认宽度，像素
  },
  {
    key: 'hfmCode',
    name: 'HFM Code',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'agency',
    name: 'Agency',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'departmentName',
    name: 'Department Name',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'employeeNumber',
    name: 'Employee Number',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'empName',
    name: 'Emp Name',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'enName',
    name: 'English Name',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 150
  },
  {
    key: 'year',
    name: 'Year',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'expenseType',
    name: 'Expense Type',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 150
  },
  {
    key: 'accrual1',
    name: 'Accrual 01',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'actualPay1',
    name: 'Actual Pay 01',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'monthPay1',
    name: 'Month Pay 01',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'balance1',
    name: 'Balance 01',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'accrual2',
    name: 'Accrual 02',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'actualPay2',
    name: 'Actual Pay 02',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'monthPay2',
    name: 'Month Pay 02',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'balance2',
    name: 'Balance 02',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'accrual3',
    name: 'Accrual 03',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'actualPay3',
    name: 'Actual Pay 03',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'monthPay3',
    name: 'Month Pay 03',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'balance3',
    name: 'Balance 03',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'accrual4',
    name: 'Accrual 04',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'actualPay4',
    name: 'Actual Pay 04',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'monthPay4',
    name: 'Month Pay 04',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'balance4',
    name: 'Balance 04',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'accrual5',
    name: 'Accrual 05',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'actualPay5',
    name: 'Actual Pay 05',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'monthPay5',
    name: 'Month Pay 05',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'balance5',
    name: 'Balance 05',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'accrual6',
    name: 'Accrual 06',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'actualPay6',
    name: 'Actual Pay 06',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'monthPay6',
    name: 'Month Pay 06',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'balance6',
    name: 'Balance 06',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'accrual7',
    name: 'Accrual 07',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'actualPay7',
    name: 'Actual Pay 07',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'monthPay7',
    name: 'Month Pay 07',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'balance7',
    name: 'Balance 07',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'accrual8',
    name: 'Accrual 08',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'actualPay8',
    name: 'Actual Pay 08',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'monthPay8',
    name: 'Month Pay 08',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'balance8',
    name: 'Balance 08',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'accrual9',
    name: 'Accrual 09',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'actualPay9',
    name: 'Actual Pay 09',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'monthPay9',
    name: 'Month Pay 09',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'balance9',
    name: 'Balance 09',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'accrual10',
    name: 'Accrual 10',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'actualPay10',
    name: 'Actual Pay 10',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'monthPay10',
    name: 'Month Pay 10',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'balance10',
    name: 'Balance 10',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'accrual11',
    name: 'Accrual 11',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'actualPay11',
    name: 'Actual Pay 11',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'monthPay11',
    name: 'Month Pay 11',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'balance11',
    name: 'Balance 11',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'accrual12',
    name: 'Accrual 12',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'actualPay12',
    name: 'Actual Pay 12',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'monthPay12',
    name: 'Month Pay 12',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'balance12',
    name: 'Balance 12',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'totalMonthPay',
    name: 'Total Month Pay',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'location',
    name: 'Location',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'legalEntityCode',
    name: 'Legal Entity Code',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'brand',
    name: 'Brand',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'hiredate',
    name: 'Hiredate',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  },
  {
    key: 'lastWorkDate',
    name: 'Last Work Date',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: false,
    width: 120
  }
]

const annualLeaveRepoTableColumn = [
  {
    key: 'period',
    name: 'Period',
    dataType: 'date', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: true, // 固定列的位置(left, right)
    width: 100 // 默认宽度，像素
  },
  {
    key: 'creator',
    name: 'Creator',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: 100
  },
  {
    key: 'uploadStatus',
    name: 'Upload Status',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: 100
  },
  {
    key: 'uploadDate',
    name: 'Upload Date',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  },
  {
    key: 'submitStatus',
    name: 'Submit Status',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  },
  {
    key: 'subimtDate',
    name: 'Submit Date',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  },
  {
    key: 'remark',
    name: 'Remark',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  }
]

const annualLeaveDetailRepoTableColumn = [
  {
    key: 'csId',
    name: '员工编号',
    dataType: 'date', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: false, // 固定列的位置(left, right)
    width: 100 // 默认宽度，像素
  },
  {
    key: 'cnName',
    name: '员工姓名',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'company',
    name: '公司',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 250
  },
  {
    key: 'location',
    name: '地区',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'org',
    name: '组织单元',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'jobTitle',
    name: '职位',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: false,
    width: 150
  },
  {
    key: 'slaryGroup',
    name: '薪资组',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'leaveRules',
    name: '休假规则',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'startDate',
    name: '入职日期',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'simulationDate',
    name: '模拟日期',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'holidayType',
    name: '假期类型',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'conversionQuota',
    name: '折算配额',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'adjustments',
    name: '调整数',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'applied',
    name: '已申请',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'carryForwardOfLastyear',
    name: '上年结转',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'expired',
    name: '已失效',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'balance',
    name: '余额',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'timeUnit',
    name: '时间单位',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'hfmCode',
    name: 'HFM Code',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'solutionHub',
    name: 'Solution Hub',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'legalEntity',
    name: 'Legal Entity',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 250
  },
  {
    key: 'brand',
    name: 'Brand',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'agency',
    name: 'Agency(Client)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'division',
    name: 'Division',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 300
  },
  {
    key: 'parentCode',
    name: 'Parent Code',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'team',
    name: 'Team',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  }
]

const FTEPaymentReportTableColumn = [
  {
    key: 'period',
    name: 'Period',
    dataType: 'date', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: false, // 固定列的位置(left, right)
    width: false // 默认宽度，像素
  },
  {
    key: 'creator',
    name: 'Creator',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: false
  },
  {
    key: 'uploadStatus',
    name: 'Upload Status',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: false
  },
  {
    key: 'uploadDate',
    name: 'Upload Date',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: false,
    width: false
  },
  {
    key: 'submitStatus',
    name: 'Submit Status',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: false
  },
  {
    key: 'subimtDate',
    name: 'Submit Date',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: false,
    width: false
  },
  {
    key: 'remark',
    name: 'Remark',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: false
  }
]

const FTEPaymentDetailTableColumn = [
  {
    key: 'month',
    name: '月份',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'hfmCode',
    name: 'HFM Code',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 250
  },
  {
    key: 'bu',
    name: 'BU',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'employeeType',
    name: '员工类型',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'csId',
    name: '工号',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'cnName',
    name: '中文姓名',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'idType',
    name: '身份证',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'socialSecurityCity',
    name: '社保缴纳地',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'startDate',
    name: '商社入职日期',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'endDate',
    name: '商社离职日期',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'pensionBase',
    name: '养老基数',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'medicalBase',
    name: '医疗基数',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'unemploymentBase',
    name: '失业基数',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'injuryBase',
    name: '工伤基数',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'fertilityBase',
    name: '生育基数',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'accumulationFundBase',
    name: '公积金基数',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'suppleAccuFundBase',
    name: '补充公积金基数',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'pensionCompProp',
    name: '养老公司比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'medicalCompProp',
    name: '医疗公司比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'medicalCompFixedValue',
    name: '医疗公司固定值',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'unemployedCompProp',
    name: '失业公司比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'indInjuryCompProp',
    name: '工伤公司比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'fertilityCompProp',
    name: '生育公司比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'providentFundCompProp',
    name: '公积金公司比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'suppleProFundCompProp',
    name: '补充公积金公司比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'endowmentInsIndProp',
    name: '养老保险个人比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personnelMedicalProp',
    name: '医疗个人比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personnelMedicalFixValue',
    name: '医疗个人固定值',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'individUnemployedProp',
    name: '失业个人比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'accuFundIndividProp',
    name: '公积金个人比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'supplAccuFundIndividProp',
    name: '补充公积金个人比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalPension',
    name: '个人养老',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalMedicalTreatment',
    name: '个人医疗',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalSerIllMedicalTreatment',
    name: '个人大病医疗',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'individualUnemployment',
    name: '个人失业',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalAccuFund',
    name: '个人公积金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'individualSuppleAccuFund',
    name: '个人补充公积金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'subtotalIndRemittance',
    name: '个人部分汇缴小计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'indPensionSupplePayment',
    name: '补缴个人养老',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalMedTreatSupplePayment',
    name: '补缴个人医疗',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalSerIllMedTreatSupplePay',
    name: '补缴个人大病医疗',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'indUnemploySupplePay',
    name: '补缴个人失业',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalAccuFundSupplePay',
    name: '补缴个人公积金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'indSuppleAccuFundSupplePay',
    name: '补缴个人补充公积金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'indSupplePaySubtotal',
    name: '个人部分补缴小计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'totalPersonalSocSecurity',
    name: '个人社保合计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'corporatePension',
    name: '公司养老',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'corporateHealthcare',
    name: '公司医疗',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companySeriousIllMedTreatment',
    name: '公司大病医疗',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companyUnemployment',
    name: '公司失业',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companyInjury',
    name: '公司工伤',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companyBirth',
    name: '公司生育',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companyProvidentFund',
    name: '公司公积金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compSuppleAccuFund',
    name: '公司补充公积金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compPartRemitSubtotal',
    name: '公司部分汇缴小计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compSupplePensionPay',
    name: '养老金公司补缴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compMedFundSupplePay',
    name: '医疗金公司补缴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compSerIllMedTreatSupple',
    name: '补缴公司大病医疗',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compSuppleUnemployIns',
    name: '失业金公司补缴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compInjuryInsSupplePay',
    name: '工伤险公司补缴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compSuppleMaternIns',
    name: '生育险公司补缴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compSuppleProvidFund',
    name: '公积金公司补缴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compSuppleSuppleProvfund',
    name: '补充公积金公司补缴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compSuppleSubtotal',
    name: '公司部分补缴小计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companyTotal',
    name: '公司部分合计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companySecurityFund',
    name: '公司残疾人保障金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'socialSecurityOverdueFine',
    name: '社保滞纳金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'socialOverallPlanningSubtotal',
    name: '社会统筹类小计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'serviceCharge',
    name: '服务费',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'serviceChargeSupplementary',
    name: '补收服务费',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'serviceChargeSubtotal',
    name: '服务费小计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'total',
    name: '总计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  }
]

const FTEPaymentNewDetailTableColumn = [
  {
    key: 'month',
    name: '月份',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'hfmCode',
    name: 'HFM Code',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 250
  },
  {
    key: 'bu',
    name: 'BU',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'employeeType',
    name: '员工类型',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'csId',
    name: '工号',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'cnName',
    name: '中文姓名',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'idType',
    name: '身份证',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'socialSecurityCity',
    name: '社保缴纳地',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'startDate',
    name: '商社入职日期',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'endDate',
    name: '商社离职日期',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'pensionBase',
    name: '养老基数',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'medicalBase',
    name: '医疗基数',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'unemploymentBase',
    name: '失业基数',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'injuryBase',
    name: '工伤基数',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'fertilityBase',
    name: '生育基数',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'accumulationFundBase',
    name: '公积金基数',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'suppleAccuFundBase',
    name: '补充公积金基数',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'pensionCompProp',
    name: '养老公司比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'medicalCompProp',
    name: '医疗公司比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'medicalCompFixedValue',
    name: '医疗公司固定值',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'unemployedCompProp',
    name: '失业公司比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'indInjuryCompProp',
    name: '工伤公司比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'fertilityCompProp',
    name: '生育公司比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'providentFundCompProp',
    name: '公积金公司比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'suppleProFundCompProp',
    name: '补充公积金公司比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'endowmentInsIndProp',
    name: '养老个人比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personnelMedicalProp',
    name: '医疗个人比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personnelMedicalFixValue',
    name: '医疗个人固定值',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'individUnemployedProp',
    name: '失业个人比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'accuFundIndividProp',
    name: '公积金个人比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'supplAccuFundIndividProp',
    name: '补充公积金个人比例',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalPension',
    name: '个人养老',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalMedicalTreatment',
    name: '个人医疗',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalSerIllMedicalTreatment',
    name: '个人大病医疗',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'individualUnemployment',
    name: '个人失业',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalAccuFund',
    name: '个人公积金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'individualSuppleAccuFund',
    name: '个人补充公积金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'subtotalIndRemittance',
    name: '个人部分汇缴小计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'indPensionSupplePayment',
    name: '补缴个人养老',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalMedTreatSupplePayment',
    name: '补缴个人医疗',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalSerIllMedTreatSupplePay',
    name: '补缴个人大病医疗',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'indUnemploySupplePay',
    name: '补缴个人失业',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalAccuFundSupplePay',
    name: '补缴个人公积金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'indSuppleAccuFundSupplePay',
    name: '补缴个人补充公积金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'indSupplePaySubtotal',
    name: '个人部分补缴小计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'totalPersonalSocSecurity',
    name: '个人社保合计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'corporatePension',
    name: '公司养老',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'corporateHealthcare',
    name: '公司医疗',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companySeriousIllMedTreatment',
    name: '公司大病医疗',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companyUnemployment',
    name: '公司失业',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companyInjury',
    name: '公司工伤',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companyBirth',
    name: '公司生育',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companyProvidentFund',
    name: '公司公积金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compSuppleAccuFund',
    name: '公司补充公积金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compPartRemitSubtotal',
    name: '公司部分汇缴小计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compSupplePensionPay',
    name: '养老金公司补缴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compMedFundSupplePay',
    name: '医疗金公司补缴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compSerIllMedTreatSupple',
    name: '补缴公司大病医疗',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compSuppleUnemployIns',
    name: '失业金公司补缴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compInjuryInsSupplePay',
    name: '工伤险公司补缴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compSuppleMaternIns',
    name: '生育险公司补缴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compSuppleProvidFund',
    name: '公积金公司补缴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compSuppleSuppleProvfund',
    name: '补充公积金公司补缴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compSuppleSubtotal',
    name: '公司部分补缴小计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companyTotal',
    name: '公司部分合计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companySecurityFund',
    name: '公司残疾人保障金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'socialSecurityOverdueFine',
    name: '社保滞纳金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'socialOverallPlanningSubtotal',
    name: '社会统筹类小计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'serviceCharge',
    name: '服务费',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'serviceChargeSupplementary',
    name: '补收服务费',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'serviceChargeSubtotal',
    name: '服务费小计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'total',
    name: '总计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  }
]

const contractorPaymentTableColumn = [
  {
    key: 'period',
    name: 'Period',
    dataType: 'date', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: true, // 固定列的位置(left, right)
    width: 100 // 默认宽度，像素
  },
  {
    key: 'creator',
    name: 'Creator',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: 100
  },
  {
    key: 'uploadStatus',
    name: 'Upload Status',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: 100
  },
  {
    key: 'uploadDate',
    name: 'Upload Date',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  },
  {
    key: 'submitStatus',
    name: 'Submit Status',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  },
  {
    key: 'subimtDate',
    name: 'Submit Date',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  },
  {
    key: 'remark',
    name: 'Remark',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  }
]

const contractorPaymentDetailTableColumn = [
  {
    key: 'center',
    name: '成本中心',
    dataType: 'date', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: false, // 固定列的位置(left, right)
    width: 100 // 默认宽度，像素
  },
  {
    key: 'name',
    name: '姓名',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'month',
    name: '发生年月',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'baseSalary',
    name: '基本工资',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'overtimeOnWeekdays',
    name: '平日加班',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'endOwment',
    name: '养老保险',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'medical',
    name: '医疗保险',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'unemployment',
    name: '失业保险',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'providentFund',
    name: '公积金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'supplementProvidentFund',
    name: '补充公积金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'allowance',
    name: '津贴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'cashGift',
    name: '礼金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'holidayOvertime',
    name: '节假日加班',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'weekendOvertime',
    name: '周末加班',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'adjustment',
    name: '调整',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'fourGoldSupplementaryDeduction',
    name: '补扣四金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compassionateLeave',
    name: '事假',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'afterTaxAllowance1',
    name: '税后津贴1',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'afterTaxDeduction1',
    name: '税后扣减1',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'noChargePreTaxAllowance1',
    name: '不收费税前津贴1',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'specAdActDeductionTotal',
    name: '专项附加实际扣除总额',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'taxAdjust',
    name: '计税调整项',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'pretax',
    name: '税前总额',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'iit',
    name: '工资税金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'netSalary',
    name: '实发工资',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'employmentStartDate',
    name: '入职日期',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'admFee',
    name: '管理费',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'currentSocialInsurance',
    name: '当月社保基数',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'currentProvidentFundBase',
    name: '当月公积金基数',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companyEndowment',
    name: '公司养老保险',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companyMedical',
    name: '公司医疗保险',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companyUnemployment',
    name: '公司失业保险',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companyEmploymentInjury',
    name: '公司工伤保险',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companyMedicalMaternity',
    name: '公司医疗生育保险',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companySecurityFund',
    name: '公司保障金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companyProvidentFund',
    name: '公司公积金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companyPaymentSubtotal',
    name: '公司合计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalEndowment',
    name: '个人养老保险',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalMedical',
    name: '个人医疗保险',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalUnemployment',
    name: '个人失业保险',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalEmploymentInjury',
    name: '个人工伤保险',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalMedicalMaternity',
    name: '个人医疗生育保险',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalProvidentFund',
    name: '个人公积金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalPaymentSubTotal',
    name: '个人合计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'agencyOfWagePayment',
    name: '代办薪酬',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'others',
    name: '其他',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'total',
    name: '总计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'remarks',
    name: '备注',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'sfsCid',
    name: '外服工号',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'idCard',
    name: '证件号码',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'internalId',
    name: '内部工号',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'taxableAmount',
    name: '应纳税金额',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'taxRate',
    name: '税率',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'vat',
    name: '增值税',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  }
]

const freelancePaymentTableColumn = [
  {
    key: 'period',
    name: 'Period',
    dataType: 'date', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: true, // 固定列的位置(left, right)
    width: 100 // 默认宽度，像素
  },
  {
    key: 'creator',
    name: 'Creator',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: 100
  },
  {
    key: 'uploadStatus',
    name: 'Upload Status',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: 100
  },
  {
    key: 'uploadDate',
    name: 'Upload Date',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  },
  {
    key: 'submitStatus',
    name: 'Submit Status',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  },
  {
    key: 'subimtDate',
    name: 'Submit Date',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  },
  {
    key: 'remark',
    name: 'Remark',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  }
]

const freelancePaymentDetailTableColumn = [
  {
    key: 'center',
    name: '成本中心',
    dataType: 'date', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: false, // 固定列的位置(left, right)
    width: 100 // 默认宽度，像素
  },
  {
    key: 'name',
    name: '姓名',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'month',
    name: '发生年月',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'baseSalary',
    name: '基本工资',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'overtimeOnWeekdays',
    name: '平日加班',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'endOwment',
    name: '养老保险',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'medical',
    name: '医疗保险',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'unemployment',
    name: '失业保险',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'providentFund',
    name: '公积金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'supplementProvidentFund',
    name: '补充公积金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'allowance',
    name: '津贴',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'cashGift',
    name: '礼金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'holidayOvertime',
    name: '节假日加班',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'weekendOvertime',
    name: '周末加班',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'adjustment',
    name: '调整',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'fourGoldSupplementaryDeduction',
    name: '补扣四金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'compassionateLeave',
    name: '事假',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'afterTaxAllowance1',
    name: '税后津贴1',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'afterTaxDeduction1',
    name: '税后扣减1',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'noChargePreTaxAllowance1',
    name: '不收费税前津贴1',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'specialAditionalActualDeductionTotalAmount',
    name: '专项附加实际扣除总额',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'taxAdjust',
    name: '计税调整项',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'pretax',
    name: '税前总额',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'iit',
    name: '工资税金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'netSalary',
    name: '实发工资',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'employmentStartDate',
    name: '入职日期',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'admFee',
    name: '管理费',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'currentSocialInsurance',
    name: '当月社保基数',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'currentProvidentFundBase',
    name: '当月公积金基数',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companyEndowment',
    name: '公司养老保险',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companyMedical',
    name: '公司医疗保险',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companyUnemployment',
    name: '公司失业保险',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companyEmploymentInjury',
    name: '公司工伤保险',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companySecurityFund',
    name: '公司保障金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companyProvidentFund',
    name: '公司公积金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'companyPaymentSubtotal',
    name: '公司合计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalEndowment',
    name: '个人养老保险',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalMedical',
    name: '个人医疗保险',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalUnemployment',
    name: '个人失业保险',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalEmploymentInjury',
    name: '个人工伤保险',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalMedicalMaternity',
    name: '个人医疗生育保险',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalProvidentFund',
    name: '个人公积金',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'personalPaymentSubTotal',
    name: '个人合计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'agencyOfWagePayment',
    name: '代办薪酬',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'others',
    name: '其他',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'total',
    name: '总计',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'remarks',
    name: '备注',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'sfsCid',
    name: '外服工号',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'idCard',
    name: '证件号码',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'internalId',
    name: '内部工号',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'taxableAmount',
    name: '应纳税金额',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'taxRate',
    name: '税率',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'vat',
    name: '增值税',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  }
]

const internPaymentTableColumn = [
  {
    key: 'period',
    name: 'Period',
    dataType: 'date', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: true, // 固定列的位置(left, right)
    width: 100 // 默认宽度，像素
  },
  {
    key: 'creator',
    name: 'Creator',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: 100
  },
  {
    key: 'uploadStatus',
    name: 'Upload Status',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: 100
  },
  {
    key: 'uploadDate',
    name: 'Upload Date',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  },
  {
    key: 'subimtDate',
    name: 'Submit Status',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  },
  {
    key: 'submitDate',
    name: 'Submit Date',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  },
  {
    key: 'remark',
    name: 'Remark',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: false
  }
]

const internPaymentDetailTableColumn = [
  {
    key: 'hfmCode',
    name: 'HFM Code',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: true, // 固定列的位置(left, right)
    width: 100 // 默认宽度，像素
  },
  {
    key: 'enName',
    name: 'English Name',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: 100
  },
  {
    key: 'cnName',
    name: 'Chinese Name',
    dataType: 'date', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: false,
    width: 150
  },

  {
    key: 'onboardingDate',
    name: 'Onboarding Date',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true,
    width: 100
  },
  {
    key: 'brand',
    name: 'Brand',
    dataType: 'date',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'department',
    name: 'Department',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'location',
    name: 'Location',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'localExpat',
    name: 'Local/Expat',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'nationality',
    name: 'Nationality',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'idNo',
    name: 'ID/Passport No.',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'beneficiaryName',
    name: 'Beneficiary Name',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'bankName',
    name: 'Bank Name',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'bankAccount',
    name: 'Bank Account',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'remark',
    name: 'Remark',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'isTaxpayer',
    name: '是否居民纳税人',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 130
  },
  {
    key: 'moreNinty',
    name: '>或<=90天',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'baseSalary',
    name: 'Base Salary',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'workingDays',
    name: 'Working Days',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'grossSalary',
    name: 'Gross Salary',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'newYearRed',
    name: '新年红包',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'taxBase',
    name: 'Tax Base',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'taxFree',
    name: 'Tax free',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'taxAble',
    name: 'Taxable',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'iit',
    name: 'IIT',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'netPay',
    name: 'Net Pay',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'payrollCheck',
    name: 'Payroll check',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'nameCheck',
    name: '姓名check',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'isFte',
    name: '是否FTE',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'lastCheck',
    name: '前月check',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 300
  }
]

export {
  ActDataTableColumn,
  ActDataDetailTableColumn,
  payrollRepoTableColumn,
  payrollDetailTableColumn,
  payrollNewDetailTableColumn,
  allowanceRepoTableColumn,
  allowanceDetailRepoTableColumn,
  annualLeaveRepoTableColumn,
  annualLeaveDetailRepoTableColumn,
  FTEPaymentReportTableColumn,
  FTEPaymentDetailTableColumn,
  FTEPaymentNewDetailTableColumn,
  contractorPaymentTableColumn,
  contractorPaymentDetailTableColumn,
  freelancePaymentTableColumn,
  freelancePaymentDetailTableColumn,
  internPaymentTableColumn,
  internPaymentDetailTableColumn
}
